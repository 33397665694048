<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div>
    <v-row
      class="pa-1"
    >
      <v-col
        cols="12"
        class="py-2 px-3 cursor-pointer"
        @click="$emit('changePaymentMethod')"
      >
        <div>
          <v-card-text class="d-flex flex-column justify-center align-start text-body-2 black--text px-0">
            <v-text-field
              v-model="computedName"
              :style="{'width': '100%'}"
              :placeholder="placeholder"
              dense
              outlined
              hide-details
            />

            <div class="d-flex flex-column">
              <v-avatar
                color="white"
                class="mt-5 mb-2 cursor-pointer"
                :style="imageData ? '': 'border: 2px dashed #3f3f3f !important;'"
                size="100"
                rounded
                @click="$refs.refInputEl.click()"
              >
                <v-img
                  :src="imageData ? urlImage : icons.teamShield"
                ></v-img>
              </v-avatar>

              <v-btn
                class="pa-0 no-bg-hover"
                color="primary"
                style="text-transform: none; font-weight: 700; text-decoration: underline;"
                text
                @click="$refs.refInputEl.click()"
              >
                {{ $t('game.add_photo') }}
              </v-btn>

              <input
                ref="refInputEl"
                type="file"
                accept=".jpeg,.png,.jpg,GIF"
                :hidden="true"
                @change="onFileSelected"
              />
            </div>
          </v-card-text>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { info } from '@core/utils/toasted'

export default {
  name: 'TeamName',
  model: {
    prop: 'name',
    event: 'update:name',
  },
  props: {
    name: {
      type: String,
      default: '',
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Ej: Fanaty Team',
    },
  },
  setup(props, { emit }) {
    const computedName = computed({
      get: () => props.name,
      set: val => {
        emit('update:name', val)
      },
    })

    const imageData = ref(null)
    const urlImage = ref(null)

    const cleanImage = () => {
      imageData.value = null
      urlImage.value = null
      emit('selectFile', null)
    }

    const onFileSelected = e => {
      if (e.target.files.length) {
        try {
          const selectedFile = e.target.files[0]
          cleanImage()
          if (selectedFile && selectedFile.size >= 262144) info('Max size of 256K!')
          const type = selectedFile ? selectedFile.type.split('/').pop() : null
          if (selectedFile && (['jpg', 'png', 'jpeg'].includes(type)) && selectedFile.size < 262144) {
            imageData.value = selectedFile
            if (selectedFile) {
              urlImage.value = URL.createObjectURL(imageData.value)
              emit('selectFile', imageData.value)
            }
          } else {
            cleanImage()
          }
        } catch (error) {
          console.error('error', error)
          cleanImage()
        }
      }
    }

    watch([computedName], () => {
      if (!computedName.value) cleanImage()
    })

    return {
      imageData,
      urlImage,

      computedName,

      onFileSelected,
      cleanImage,

      icons: {
        teamShield: require('@/assets/images/svg/team-shield.svg'),
      },
    }
  },
}
</script>
