<template>
  <div>
    <v-radio-group
      v-model="selectedRadio"
      class="ma-0"
      hide-details
    >
      <v-row>
        <v-col
          v-for="(team, index) in teams"
          :key="index"
          cols="12"
          class="py-2 px-3"
        >
          <v-card
            width="100%"
            rounded="lg"
            class="cursor-pointer"
            :color="selectedRadio === team.id ? '#14ae5c40' : 'white'"
            :style="selectedRadio === team.id ? 'border: 2px solid #14ae5c !important;' : ''"
            @click="selectedRadio = team.id"
          >
            <v-card-text class="d-flex justify-space-between align-center text-body-2 black--text">
              <div style="width: 100%;">
                <div class="d-flex flex-column">
                  <span class="d-flex justify-space-between">
                    <span class="text-body-1">
                      <v-avatar
                        color="white"
                        class="mr-2 cursor-pointer"
                        size="30"
                        rounded
                        :style="team.logo ? '' : 'border: 1px solid #3f3f3f !important;'"
                        @click="$refs.refInputEl.click()"
                      >
                        <v-img
                          :src="team.logo ? team.logo : icons.teamShield"
                        ></v-img>
                      </v-avatar>

                      <span class="font-weight-bold">{{ team.name }}</span>
                    </span>
                    <v-radio
                      :key="team.id"
                      :value="team.id"
                      color="#14ae5c"
                    ></v-radio>
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-radio-group>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { formatCurrency } from '@core/utils'

export default {
  name: 'SelectTeam',
  model: {
    prop: 'selected',
    event: 'update:selected',
  },
  props: {
    selected: {
      type: Number,
      default: 1,
    },
    teams: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props, { emit }) {
    const selectedRadio = computed({
      get: () => props.selected,
      set: value => emit('update:selected', value),
    })

    return {
      selectedRadio,

      formatCurrency,

      icons: {
        teamShield: require('@/assets/images/svg/team-shield.svg'),
      },
    }
  },
}
</script>

<style lang="scss">
.selected-card {
  border: 1px solid #14ae5c !important;
  background-color: rgba(20, 174, 92, 0.25) !important;
}
</style>
