<template>
  <div class="game-wrapper game">
    <div class="game-inner">
      <v-card-text>
        <div
          class="ms-0 align-start"
        >
          <v-row class="px-1 py-1">
            <v-col
              cols="12"
              class="py-1 px-3 d-flex justify-space-between align-center"
            >
              <div class="d-flex">
                <h1 class="text-h6 font-weight-bold black--text">
                  {{ t('game.choose_team') }}
                </h1>
              </div>

              <div>
                <v-btn
                  v-if="!privateLanding"
                  class="ma-0 py-2 px-0 no-bg-hover font-weight-bold text-body-1"
                  style="
                    text-transform: inherit;
                    min-width: 60px;
                  "
                  text
                  color="secondary"
                  @click="onLogin()"
                >
                  {{ t('login.login') }}
                </v-btn>

                <app-bar-user-avatar
                  v-else
                  class="ms-2"
                  :show-question="true"
                  :title="$t('reservations.you_are_leaving')"
                  :confirm-button-text="$t('player_actions.leave')"
                  @logout="onLogout"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-text
        v-if="teamsOptions.length"
        class="mt-5"
      >
        <v-card-title class="text-body-1 font-weight-bold black--text pa-0 pb-4">
          <span>{{ $t('game.choose_team_play') }}</span>
        </v-card-title>

        <select-team
          :selected.sync="myTeam"
          :teams="teamsOptions"
        />
      </v-card-text>

      <v-card-text
        class="pt-0 mt-5"
        :class="{'mt-5': !teamsOptions.length}"
        :style="'padding-bottom: 150px;'"
      >
        <v-card-title class="text-body-1 font-weight-bold black--text pa-0">
          {{ teamsOptions.length ? $t('game.choose_team_name') : $t('game.create_your_team') }}
        </v-card-title>

        <team-name
          :name.sync="name"
          @selectFile="val => imageData = val"
        />
      </v-card-text>

      <v-card-actions
        class="pay-card-action py-3 d-flex- flex-column"
      >
        <v-btn
          class="text-body-1 font-weight-bold mb-2"
          style="border-radius: 33px; text-transform: initial;"
          color="primary"
          rounded
          large
          block
          @click="onContinue"
        >
          {{ t('tooltip.continue') }}
        </v-btn>

        <!-- <v-btn
          class="text-body-1 font-weight-bold mb-2"
          style="border-radius: 33px; text-transform: initial;"
          color="primary"
          rounded
          outlined
          large
          block
          @click="goToRV"
        >
          <span>{{ $t('tooltip.later') }}</span>
        </v-btn> -->
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import {
  mdiCreditCardOutline, mdiFoodAppleOutline, mdiCalendar, mdiClose,
} from '@mdi/js'
import { ref, watch, onMounted } from '@vue/composition-api'
import { required, minLengthValidator, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { useRouter, formatCurrency, formatStdDate } from '@core/utils'
import themeConfig from '@themeConfig'
import useCryptoJs from '@core/utils/useCryptoJs'
import useLogData from '@core/utils/useLogData'
import {
  createTeam, getUserTeams, uploadTeamLogo, associateMatchTeam,
} from '@api'

import AppBarUserAvatar from '@core/layouts/components/app-bar/AppBarUserAvatar.vue'
import SelectTeam from '@/views/choose-team/components/select-team/SelectTeam.vue'
import TeamName from '@/views/choose-team/components/team-name/TeamName.vue'

export default {
  name: 'ChooseTeam',
  components: {
    AppBarUserAvatar,
    SelectTeam,
    TeamName,
  },
  setup() {
    const { t } = useUtils()
    const { userData } = useCryptoJs()
    const { route, router } = useRouter()
    const { logData, updateLogData } = useLogData()

    const privateLanding = ref(null)
    const dataParams = ref(null)
    const reservationCode = ref(null)
    const matchTeamCode = ref(null)
    const type = ref(null)
    const userLogged = ref(null)

    const name = ref('')
    const imageData = ref(null)
    const myTeam = ref(null)
    const teamsOptions = ref([])

    // remove team
    const teamSelected = ref(null)

    const onLogin = async () => {
      logData.value.joinGame = 'NO'
      logData.value.reservationCode = reservationCode.value
      logData.value.redirect = 'landing'
      logData.value.field = 'all'
      logData.value.date = 'all'
      logData.value.time = 'all'

      // remove team
      logData.value.team = teamSelected.value
      await updateLogData(logData.value)

      router.push({ name: 'auth-login-phone-email' })
    }

    const onLogout = async () => {
      await localStorage.removeItem('userData')
      teamsOptions.value = []
      privateLanding.value = false
    }

    const goToRV = () => {
      router.replace({ name: 'reservation', params: { code: reservationCode.value, team: teamSelected.value, payType: type.value } })
    }

    const associateTeam = async id => {
      const associateResp = await associateMatchTeam({
        match_code: matchTeamCode.value,
        team_id: id,
        letter: teamSelected.value,
      })

      if (associateResp.ok) goToRV()
    }

    const onCreateTeam = async () => {
      const body = {
        name: name.value,
        user_id: userData.value.user_id,
      }
      const resp = await createTeam(body)
      if (resp.ok) {
        name.value = ''
        myTeam.value = null

        if (imageData.value) {
          const formData = new FormData()
          formData.append('file', imageData.value)
          await uploadTeamLogo(formData, resp.data.team_id)
        }

        await associateTeam(resp.data.team_id)
      }
    }

    const onContinue = async () => {
      if (userLogged.value) {
        if (name.value) {
          await onCreateTeam()
        } else if (myTeam.value) {
          await associateTeam(myTeam.value)
        }
      } else {
        onLogin(true)
      }
    }

    const fetchTeams = async () => {
      try {
        const response = await getUserTeams()
        if (response.ok) teamsOptions.value = response.data
        else teamsOptions.value = []
      } catch (e) {
        console.error(e)
        teamsOptions.value = []
      }
    }

    const initChooseTeam = async () => {
      userLogged.value = await JSON.parse(localStorage.getItem('userData'))
      privateLanding.value = !!userLogged.value

      // remove team
      // const { code, team, showPay } = route.value.params
      const {
        code, team, matchCode, payType,
      } = route.value.params

      reservationCode.value = code
      teamSelected.value = `${team}`.toUpperCase()
      matchTeamCode.value = matchCode
      type.value = payType

      if (userLogged.value) await fetchTeams()
    }

    watch([myTeam], () => {
      if (myTeam.value) {
        name.value = null
        imageData.value = null
      }
    })

    onMounted(async () => {
      await initChooseTeam()
    })

    return {
      privateLanding,
      dataParams,
      reservationCode,
      userData,
      userLogged,

      name,
      imageData,
      myTeam,
      teamsOptions,

      // methods
      formatCurrency,
      onLogin,
      onLogout,
      formatStdDate,

      onContinue,
      onCreateTeam,
      fetchTeams,
      goToRV,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,

      // validations
      validators: {
        required,
        minLengthValidator,
        integerValidator,
      },

      t,

      icons: {
        mdiCreditCardOutline,
        mdiFoodAppleOutline,
        mdiCalendar,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/view.scss';
</style>

<style lang="scss" scoped>
// @import '@core/preset/preset/pages/landing.scss';
@import '@core/preset/preset/pages/game.scss';

.theme--dark.v-divider {
  border-color: #333333;
}

.bg-grey {
  background-color: #979797;
}
</style>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.v-input__append-inner {
  max-width: auto 0px;
}

.white-border {
  border: 2px solid #ffffff !important;
}

.app-content-container {
  padding: 0px !important;
}

.card-img {
  height: auto;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
</style>
